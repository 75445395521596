import KeyMirror from 'keymirror';

import ProductsTypes from '../action-types/products';
import AuthTypes from '../action-types/auth';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        products: [],
        requestStatus: null,
        errorMsg: null,
        subscription: {
            showError: false,
            requestStatus: null,
            errorMsg: null,
        },
    }),
};

const shouldShowError = (prevSubscription, newSubscription) => {
    if (prevSubscription.status === newSubscription.status) {
        return prevSubscription.showError;
    }

    // see here for error requirements based on status:
    // https://theoryandprinciple.atlassian.net/wiki/spaces/LEX/pages/706412561/User+Subscription+Account+Status
    if (['past_due', 'incomplete', 'incomplete_expired', 'canceled', 'unpaid'].includes(newSubscription.status)) {
        return true;
    }

    return false;
};

const ProductsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case ProductsTypes.FETCH_PRODUCTS_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
            errorMsg: null,
        };
    case ProductsTypes.FETCH_PRODUCTS_SUCCESS:
        return {
            ...state,
            products: payload,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case ProductsTypes.FETCH_PRODUCTS_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case ProductsTypes.CREATE_SUBSCRIPTION_BEGIN:
    case ProductsTypes.FETCH_SUBSCRIPTION_BEGIN:
    case ProductsTypes.SETUP_PAYMENT_BEGIN:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                requestStatus: REQUEST_STATUS.ACTIVE,
                errorMsg: null,
            },
        };
    case ProductsTypes.CREATE_SUBSCRIPTION_SUCCESS:
    case ProductsTypes.FETCH_SUBSCRIPTION_SUCCESS:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                ...payload.subscription,
                showError: shouldShowError(state.subscription, payload.subscription),
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case ProductsTypes.SETUP_PAYMENT_SUCCESS:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                status: 'active',
                showError: false,
                requestStatus: REQUEST_STATUS.SUCCESS,
            },
        };
    case ProductsTypes.CREATE_SUBSCRIPTION_ERROR:
    case ProductsTypes.FETCH_SUBSCRIPTION_ERROR:
    case ProductsTypes.SETUP_PAYMENT_ERROR:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                requestStatus: REQUEST_STATUS.ERROR,
                errorMsg: payload.errorMsg,
            },
        };
    case ProductsTypes.UPDATE_SHOW_SUBSCRIPTION_ERROR:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                showError: payload.showError,
            },
        };
    case ProductsTypes.CLEAR_SUBSCRIPTION_STATUS:
        return {
            ...state,
            subscription: {
                ...state.subscription,
                status: null,
            },
        };

    // clear all stored subscription data on logout
    case AuthTypes.LOGOUT:
        return {
            ...internals.initial(),
            products: [
                ...state.products,
            ],
        };

    default:
        // do nothing
    }
    return state;
};

export default ProductsReducer;
