import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const PrimaryButton = ({
    children,
    onClick,
    disabled,
    disabledMessage,
    ...props
}) => {
    const [isDisabledMessageOpen, setDisabledMessageOpen] = useState(false);

    const handleClick = (event) => {
        if (disabledMessage && disabled) {
            setDisabledMessageOpen(true);
            return;
        }

        if (onClick) {
            onClick(event);
        }
    };

    const handleDisabledMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisabledMessageOpen(false);
    };

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                aria-disabled={disabled}
                disabled={!disabledMessage && disabled} // only truly disable if we aren't using the disabledMessage
                disableElevation
                disableFocusRipple
                {...props} // eslint-disable-line react/jsx-props-no-spreading
            >
                { children }
            </Button>

            {disabledMessage && (
                <Snackbar
                    open={isDisabledMessageOpen}
                    autoHideDuration={6000}
                    onClose={handleDisabledMessageClose}
                    message={disabledMessage}
                    action={(
                        <IconButton size="small" aria-hidden color="inherit" onClick={handleDisabledMessageClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    )}
                />
            )}
        </>
    );
};

PrimaryButton.defaultProps = {
    children: null,
    disabled: false,
    onClick: null,
    disabledMessage: null,
};

PrimaryButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
};

export default PrimaryButton;
