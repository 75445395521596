import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

const TextButton = ({ children, onClick, ...props }) => (
    <Button
        color="primary"
        onClick={onClick}
        disableFocusRipple
        {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
        { children }
    </Button>
);

TextButton.defaultProps = {
    children: null,
    onClick: null,
};

TextButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
};

export default TextButton;
