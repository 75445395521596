import WebClient from '../utils/web-client';
import AuthTypes from '../action-types/auth';
import history from '../wiring/history';

import { setupUser } from './user';
import { fetchSubscription } from './products';

export const ClearErrors = () => ({
    type: AuthTypes.INITIALIZE_APP,
});

export const SignUpBegin = () => ({
    type: AuthTypes.SIGN_UP_BEGIN,
});
const SignUpSuccess = () => ({
    type: AuthTypes.SIGN_UP_SUCCESS,
});
const SignUpError = (errorMsg) => ({
    type: AuthTypes.SIGN_UP_ERROR,
    payload: { errorMsg },
});
export const SignUp = (firstName, lastName, email, onSuccess, onError) => async (dispatch) => {
    dispatch(SignUpBegin());

    try {
        const payload = {
            firstName,
            lastName,
            email,
        };

        await WebClient.post('/users', payload);
        dispatch(SignUpSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && error.response.status === 409) {
            errorType = 'USER_ALREADY_EXISTS';
        }

        dispatch(SignUpError(errorType));
        if (onError) onError(errorType);
    }
};

export const ResendSetPassword = (email, onSuccess, onError) => async (dispatch) => {
    dispatch(SignUpBegin());

    try {
        const payload = {
            email,
        };
        await WebClient.post('/users/resend-set-password', payload);
        dispatch(SignUpSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.status === 404
                || error.response.status === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(SignUpError(errorType));
        if (onError) onError(errorType);
    }
};

export const LoginBegin = () => ({
    type: AuthTypes.LOGIN_BEGIN,
});
const LoginSuccess = (token, role) => ({
    type: AuthTypes.LOGIN_SUCCESS,
    payload: {
        token,
        role,
    },
});
const LoginError = (errorMsg) => ({
    type: AuthTypes.LOGIN_ERROR,
    payload: { errorMsg },
});

export const Login = (email, password, onSuccess, onError) => async (dispatch) => {
    dispatch(LoginBegin());

    try {
        const { data } = await WebClient.post(
            '/login',
            { email, password },
            { responseType: 'text' },
        );

        // update auth token
        WebClient.updateAuth(data.token);

        dispatch(setupUser(data.user));
        dispatch(LoginSuccess(data.token, data.user.role));

        const loginSuccess = () => {
            if (onSuccess) onSuccess();

            history.push('/app/upload');
        };
        const loginError = () => {
            history.push('/app/error/general');
        };

        dispatch(fetchSubscription(loginSuccess, loginError));
    } catch (error) {
        let errorType = 'UNKNOWN';
        if (error.response && (error.response.status === 401)) {
            errorType = 'UNAUTHORIZED';
        }
        if (error.response && (error.response.status === 422)) {
            errorType = 'UNPROCESSABLE';
        }
        dispatch(LoginError(errorType));
        if (onError) onError(errorType);
    }
};

export const Logout = () => ({
    type: AuthTypes.LOGOUT,
});

export const ForgotPassBegin = () => ({
    type: AuthTypes.FORGOT_PASSWORD_BEGIN,
});
const ForgotPassSuccess = () => ({
    type: AuthTypes.FORGOT_PASSWORD_SUCCESS,
});
const ForgotPassError = (errorMsg) => ({
    type: AuthTypes.FORGOT_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ForgotPass = (email, onSuccess, onError) => async (dispatch) => {
    dispatch(ForgotPassBegin());

    try {
        await WebClient.post('/users/request-reset', { email });
        dispatch(ForgotPassSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.status === 404
                || error.response.status === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ForgotPassError(errorType));
        if (onError) onError(errorType);
    }
};

export const ResetPassBegin = () => ({
    type: AuthTypes.RESET_PASSWORD_BEGIN,
});
const ResetPassSuccess = () => ({
    type: AuthTypes.RESET_PASSWORD_SUCCESS,
});
const ResetPassError = (errorMsg) => ({
    type: AuthTypes.RESET_PASSWORD_ERROR,
    payload: { errorMsg },
});
export const ResetPass = (email, resetToken, password, onSuccess, onError) => async (dispatch) => {
    dispatch(ResetPassBegin());

    try {
        await WebClient.post('/users/reset-password', {
            email,
            newPassword: password,
            resetToken,
        });
        dispatch(ResetPassSuccess());
        dispatch(Login(email, password, onSuccess, onError));
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (
            error.response.error === 404
                || error.response.error === 400
        )) {
            errorType = 'NOT_FOUND';
        }

        dispatch(ResetPassError(errorType));
        if (onError) onError(errorType);
    }
};

const CheckTokenBegin = () => ({
    type: AuthTypes.CHECK_TOKEN_BEGIN,
});
const CheckTokenSuccess = () => ({
    type: AuthTypes.CHECK_TOKEN_SUCCESS,
});
const CheckTokenError = () => ({
    type: AuthTypes.CHECK_TOKEN_ERROR,
});

export const CheckToken = () => async (dispatch) => {
    dispatch(CheckTokenBegin());

    try {
        await WebClient.get('/users/authenticated');
        dispatch(CheckTokenSuccess());
    } catch (error) {
        dispatch(CheckTokenError());
        dispatch(Logout());
    }
};
