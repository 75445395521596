const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    FETCH_PRODUCTS_BEGIN: true,
    FETCH_PRODUCTS_SUCCESS: true,
    FETCH_PRODUCTS_ERROR: true,
    CREATE_SUBSCRIPTION_BEGIN: true,
    CREATE_SUBSCRIPTION_SUCCESS: true,
    CREATE_SUBSCRIPTION_ERROR: true,
    SETUP_PAYMENT_BEGIN: true,
    SETUP_PAYMENT_SUCCESS: true,
    SETUP_PAYMENT_ERROR: true,
    FETCH_SUBSCRIPTION_BEGIN: true,
    FETCH_SUBSCRIPTION_SUCCESS: true,
    FETCH_SUBSCRIPTION_ERROR: true,
    UPDATE_SHOW_SUBSCRIPTION_ERROR: true,
    CLEAR_SUBSCRIPTION_STATUS: true,
});
