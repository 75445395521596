import WebClient from '../utils/web-client';
import UserTypes from '../action-types/user';
import { getUserId } from '../selectors/user';

export const setupUser = (user) => ({
    type: UserTypes.SETUP_USER,
    payload: user,
});

export const acceptCookiePolicy = () => ({
    type: UserTypes.ACCEPT_COOKIE_POLICY,
});

const updateUserBegin = () => ({
    type: UserTypes.UPDATE_USER_BEGIN,
});
const updateUserSuccess = (firstName, lastName, email) => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
    payload: {
        firstName,
        lastName,
        email,
    },
});
const updateUserError = (errorMsg) => ({
    type: UserTypes.UPDATE_USER_ERROR,
    payload: { errorMsg },
});
export const updateUser = (userInfo, onSuccess, onError) => async (dispatch, getState) => {
    const userId = getUserId(getState());

    dispatch(updateUserBegin());

    try {
        const payload = {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            email: userInfo.email,
        };

        if (userInfo.password) {
            payload.password = userInfo.password;
        }

        await WebClient.patch(`/users/${userId}`, payload);
        dispatch(updateUserSuccess(userInfo.firstName, userInfo.lastName, userInfo.email));
        if (onSuccess) onSuccess();
    } catch (error) {
        let errorType = 'UNKNOWN';

        if (error.response && (error.response.status === 403)) {
            errorType = 'FORBIDDEN';
        }
        if (error.response && (error.response.status === 409)) {
            errorType = 'CONFLICT';
        }
        if (error.response && (error.response.status === 400)) {
            errorType = 'BAD_REQUEST';
        }

        dispatch(updateUserError(errorType));
        if (onError) onError(errorType);
    }
};
