export default {
    white: '#FFFFFF',
    whiteRGB: '255, 255, 255',
    whiteDark: '#F8F8F8',
    whiteDarkRGB: '248, 248, 248',
    black: '#000000',
    blackRGB: '0, 0, 0',
    greyLight: '#979797',
    greyLightRGB: '151, 151, 151',
    greyDark: '#595959',
    purple: '#7936F0',
    purpleRGB: '121, 54, 240',
    purpleHighlight: '#8540FF',
    purpleMedium: '#6200EE',
    purpleMediumRGB: '98, 0, 238',
    purpleLight: '#F4E0FF',
    purpleDark: '#3E0060',
    purpleDarkRGB: '62, 0, 96',
    purpleDarkHighlight: '#3B1A75',
    purpleDarkLowlight: '#400044',
    highlight: '#00E4D9',
    error: '#B00020',
    errorLight: '#FFBEC5',
    success: '#13AE47',
};
