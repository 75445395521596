import UserTypes from '../action-types/user';
import AuthTypes from '../action-types/auth';

const internals = {
    initial: () => ({
        user: {},
        cookiePolicyAccepted: false,
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case UserTypes.SETUP_USER:
        return {
            ...state,
            user: {
                ...payload,
            },
        };
    case UserTypes.UPDATE_USER_SUCCESS:
        return {
            ...state,
            user: {
                ...state.user,
                ...payload,
            },
        };
    case UserTypes.ACCEPT_COOKIE_POLICY:
        return {
            ...state,
            cookiePolicyAccepted: true,
        };

    // clear all stored user data on logout
    case AuthTypes.LOGOUT:
        return {
            ...internals.initial(),
            // set to true because we know the user has an account if they just signed out of it
            cookiePolicyAccepted: true,
        };

    default:
        // do nothing
    }
    return state;
};

export default UserReducer;
