import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import 'bootstrap-css-only/css/bootstrap-grid.min.css';

// REDUX
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import ScrollToTop from './components/scroll-to-top';
import ErrorFallback from './components/error-fallback';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ClearErrors } from './actions/auth';
import reducers from './reducers';

import browserHistory from './wiring/history';
import { OfficeProvider } from './wiring/use-office';
import App from './routes';

import Initializers from './initializers';
import InitializerUser from './initializers/user';

import GAUtils from './utils/ga';

import 'sanitize.css/sanitize.css';
import './index.css';

try {
    const initialState = {};
    const enhancers = [];
    const middleware = [thunk, routerMiddleware(browserHistory)];

    if (process.env.NODE_ENV === 'development') {
        const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

        if (typeof devToolsExtension === 'function') {
            enhancers.push(devToolsExtension());
        }
    }

    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line global-require
        const axe = require('@axe-core/react');
        axe(React, ReactDOM, 1000);
    }

    if (process.env.REACT_APP_SENTRY_DSN) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: process.env.REACT_APP_SENTRY_ENV,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
                }),
                new CaptureConsoleIntegration(),
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            tracesSampleRate: 1.0,
        });
    }

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);
    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['auth', 'user', 'products', 'transcript'],
    };
    const persistedReducer = persistReducer(persistConfig, reducers(browserHistory));
    const store = createStore(
        persistedReducer,
        initialState,
        composedEnhancers,
    );
    const persistedStore = persistStore(store);

    const onBeforeLift = () => {
        // Run initializers... anything that will need to use or subscribe to the store
        Initializers(store);

        // clear login/logout errors that may be in local storage
        store.dispatch(ClearErrors());

        if (store.getState().auth.isAuthenticated) {
            InitializerUser(store);
        }
    };

    if (process.env.REACT_APP_GA || process.env.REACT_APP_GA4) {
        GAUtils.initGA();
    }

    ReactDOM.render(
        <Provider store={store}>
            <PersistGate
                loading={null}
                persistor={persistedStore}
                onBeforeLift={onBeforeLift}
            >
                <ConnectedRouter history={browserHistory}>
                    <Sentry.ErrorBoundary fallback={() => <ErrorFallback />}>
                        <ScrollToTop />
                        <Elements stripe={stripePromise}>
                            <OfficeProvider>
                                <App store={store} />
                            </OfficeProvider>
                        </Elements>
                    </Sentry.ErrorBoundary>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
        ,
        document.getElementById('root'),
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
} catch (error) {
    ReactDOM.render(<ErrorFallback />);
}
