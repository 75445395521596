import * as Sentry from '@sentry/react';

import WebClient from '../utils/web-client';
import ProductsTypes from '../action-types/products';
import { getCustomerId, getUserId } from '../selectors/user';

const fetchProductsBegin = () => ({
    type: ProductsTypes.FETCH_PRODUCTS_BEGIN,
});
const fetchProductsSuccess = (products) => ({
    type: ProductsTypes.FETCH_PRODUCTS_SUCCESS,
    payload: products,
});
const fetchProductsError = (errorMsg) => ({
    type: ProductsTypes.FETCH_PRODUCTS_ERROR,
    payload: { errorMsg },
});

export const fetchProducts = () => (
    async (dispatch) => {
        dispatch(fetchProductsBegin());

        try {
            const { data } = await WebClient.get('/products');

            dispatch(fetchProductsSuccess(data));
        } catch (error) {
            dispatch(fetchProductsError(error));
        }
    }
);

const createSubscriptionBegin = () => ({
    type: ProductsTypes.CREATE_SUBSCRIPTION_BEGIN,
});
const createSubscriptionSuccess = (subscription) => ({
    type: ProductsTypes.CREATE_SUBSCRIPTION_SUCCESS,
    payload: { subscription },
});
const createSubscriptionError = (errorMsg) => ({
    type: ProductsTypes.CREATE_SUBSCRIPTION_ERROR,
    payload: { errorMsg },
});

export const createSubscription = (priceId, productId, onSuccess, onError) => (
    async (dispatch, getState) => {
        dispatch(createSubscriptionBegin());

        try {
            const customerId = getCustomerId(getState());
            const payload = {
                customerId,
                priceId,
                productId,
            };

            const { data } = await WebClient.post('/subscription', payload);

            dispatch(createSubscriptionSuccess({ ...data, priceId }));

            if (onSuccess) onSuccess(data.clientSecret);
        } catch (error) {
            let errorType = 'UNKNOWN';
            if (error.response && (error.response.status === 409)) {
                errorType = 'ALREADY_SUBSCRIBED';
            }
            if (error.response && (error.response.status === 403)) {
                errorType = 'FORBIDDEN';
            }

            dispatch(createSubscriptionError(errorType));
            if (onError) onError(errorType);
        }
    }
);

const fetchSubscriptionBegin = () => ({
    type: ProductsTypes.FETCH_SUBSCRIPTION_BEGIN,
});
const fetchSubscriptionSuccess = (subscription) => ({
    type: ProductsTypes.FETCH_SUBSCRIPTION_SUCCESS,
    payload: { subscription },
});
const fetchSubscriptionError = (errorMsg) => ({
    type: ProductsTypes.FETCH_SUBSCRIPTION_ERROR,
    payload: { errorMsg },
});

export const fetchSubscription = (onSuccess, onError) => (
    async (dispatch, getState) => {
        dispatch(fetchSubscriptionBegin());

        try {
            const userId = getUserId(getState());

            const { data } = await WebClient.get(`/users/${userId}/subscriptions/current`);

            dispatch(fetchSubscriptionSuccess(data));

            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(fetchSubscriptionError(error));
            if (onError) onError(error);
        }
    }
);

const setupPaymentBegin = () => ({
    type: ProductsTypes.SETUP_PAYMENT_BEGIN,
});
const setupPaymentSuccess = () => ({
    type: ProductsTypes.SETUP_PAYMENT_SUCCESS,
});
const setupPaymentError = (errorMsg) => ({
    type: ProductsTypes.SETUP_PAYMENT_ERROR,
    payload: { errorMsg },
});

export const setupPayment = (stripe, clientSecret, card, payment, onSuccess, onError) => (
    async (dispatch, getState) => {
        dispatch(setupPaymentBegin());

        try {
            const payload = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card,
                    billing_details: {
                        name: payment.nameOnCard,
                        address: {
                            postal_code: payment.zip,
                            country: payment.region,
                        },
                    },
                },
            });

            if (payload.error) {
                const { type, message, setup_intent: setupIntent } = payload.error;
                let newErrorMessage = `Error type ${type}`;

                if (setupIntent && setupIntent.id) {
                    newErrorMessage += ` on SetupIntent ${setupIntent.id}`;
                }

                newErrorMessage += `: ${message}`;

                // if the error is related to an issue that the user can self-serve on
                // see possible error types here: https://stripe.com/docs/api/errors#errors-type
                if (['card_error', 'invalid_request_error'].includes(type)) {
                    // show the error to the user
                    if (onError) onError(message);

                    dispatch(setupPaymentError(newErrorMessage));
                    return;
                }

                throw new Error(newErrorMessage, { cause: payload.error });
            }

            dispatch(setupPaymentSuccess());
            if (onSuccess) onSuccess();
        } catch (error) {
            dispatch(setupPaymentError(error));

            // errors that can be displayed to the user to self-serve are handled above
            // if we end up here then it's likely one of the following problems: integration error, server/network issue, something else unexpected
            // so just log to Sentry
            const customerId = getCustomerId(getState());
            Sentry.captureException(`An error occurred calling stripe.confirmCardSetup for customer ${customerId}: ${error}`);

            // and display a generic error to the user
            if (onError) onError('There was an issue processing your payment.  Please try again later.');
        }
    }
);

export const getCustomerPortalUrl = (returnUrl, onSuccess, onError) => (
    async (dispatch, getState) => {
        try {
            const customerId = getCustomerId(getState());
            const payload = {
                customerId,
                returnUrl,
            };

            const { data } = await WebClient.post('/customer-portal', payload);

            if (onSuccess) onSuccess(data.url);
        } catch (error) {
            let errorType = 'UNKNOWN';

            if (error.response && (error.response.status === 403)) {
                errorType = 'FORBIDDEN';
            }
            if (error.response && (error.response.status === 400)) {
                errorType = 'BAD_REQUEST';
            }

            if (onError) onError(errorType);
        }
    }
);

export const updateShowSubscriptionError = (showError) => ({
    type: ProductsTypes.UPDATE_SHOW_SUBSCRIPTION_ERROR,
    payload: { showError },
});

export const clearSubscriptionStatus = () => ({
    type: ProductsTypes.CLEAR_SUBSCRIPTION_STATUS,
});
