import { createSelector } from 'reselect';

const getUserState = (state) => state.user;

export const getUser = createSelector(
    getUserState,
    (userState) => userState.user,
);

export const getCustomerId = createSelector(
    getUser,
    (user) => user.stripeCustomerId,
);

export const getUserId = createSelector(
    getUser,
    (user) => user.id,
);

export const getCookiePolicyAcceptance = createSelector(
    getUserState,
    (userState) => userState.cookiePolicyAccepted,
);
