const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 120px)', // subtract space for the footer
    },
    title: {
        textAlign: 'center',
        fontSize: '5rem',
        fontWeight: 'bold',
        lineHeight: '5.8rem',
        letterSpacing: '0.00304rem',
        paddingTop: 54,
        paddingBottom: 22,
    },
    description: {
        textAlign: 'center',
        fontSize: '1.6rem',
        fontWeight: '400',
        lineHeight: '2.9rem',
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        height: 190,
        width: 313,
    },
});

export default styles;
