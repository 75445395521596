import { createTheme } from '@material-ui/core/styles';
import Colors from './colors';
// https://material-ui.com/customization/default-theme/?expend-path=$.palette.background
// https://fonts.adobe.com/fonts/ibm-plex-sans#fonts-section
const theme = createTheme({
    breakpoints: { // using bootstrap defined breakpoints for consistency
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    overrides: {
        MuiFormControl: {
            root: {
                width: '100%',
            },
        },
        MuiFormControlLabel: { // checkboxes, radio buttons
            root: {
                '&.Checkbox-label': {
                    color: Colors.greyLight,
                    lineHeight: '0.2375rem',
                },

                '&.Checkbox-label-checked': {
                    color: Colors.greyLight,
                },

                '&.Radio-label': {
                    color: `rgba(${Colors.whiteRGB}, 0.7)`,
                    padding: '6px 13px',
                    lineHeight: '0.11875rem',

                    '&:hover': {
                        outline: `4px solid ${Colors.greyDark}`,
                        borderRadius: 2,
                    },
                },

                '&.Radio-label-checked': {
                    color: Colors.white,
                },
            },

            label: {
                color: 'inherit',
                fontSize: '1.6rem',
                fontWeight: 400,
                lineHeight: 'inherit',
            },
        },
        MuiFormLabel: {
            root: {
                color: Colors.greyDark,
            },
        },
        MuiInputLabel: {
            root: {
                color: Colors.greyDark,
            },
        },
        MuiFormHelperText: {
            root: {
                color: Colors.greyDark,
            },
        },
        MuiInputBase: {
            root: {
                color: Colors.black,
            },
        },
        MuiOutlinedInput: {
            root: {
                color: Colors.black,
                fontSize: '1.6rem',
                padding: '2px 0',
                borderRadius: 4,

                '& $notchedOutline': {
                    border: `1px solid ${Colors.greyLight}`,
                },
                '&$focused': {
                    '& $notchedOutline': {
                        borderColor: Colors.purple,
                    },
                },
                '&:hover': {
                    '& $notchedOutline': {
                        borderColor: Colors.greyDark,
                    },
                },
                '&$error$focused': {
                    '& $notchedOutline': {
                        borderColor: Colors.error,
                    },
                },
                '&$disabled': {
                    '& $notchedOutline': {
                        color: Colors.greyLight,
                        borderColor: Colors.greyLight,
                    },
                },
            },
            input: {
                padding: 15,

                '&::placeholder': {
                    color: Colors.greyDark,
                },
            },
        },
        MuiRadio: {
            root: {
                color: `rgba(${Colors.whiteRGB}, 0.7)`,

                '&:hover': {
                    backgroundColor: `rgba(${Colors.whiteRGB}, 0.1)`,
                },

                '&.Mui-checked': {
                    color: Colors.white,
                },
            },
        },
        MuiSelect: {
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiMenuItem: {
            root: {
                color: Colors.black,
                fontSize: '1.6rem',
                fontWeight: 400,
                lineHeight: 1.1875,

                '&:hover': {
                    color: Colors.black,
                    backgroundColor: `rgba(${Colors.greyLightRGB}, 0.3)`,
                },

                '&.Mui-selected': {
                    color: Colors.black,
                    backgroundColor: `rgba(${Colors.greyLightRGB}, 0.2)`,
                },

                '&[role=option]': { // select menu options
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingLeft: 22,
                    paddingRight: 22,
                },

                '&[role=menu]': { // menu items

                },
            },
        },
        MuiListItem: {
            button: {
                color: Colors.purpleMedium,
                fontSize: '1.6rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                letterSpacing: '0.0313rem',

                '&:hover': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.08)`,
                },
            },
        },
        MuiSvgIcon: {
            root: {
                '&.MuiSelect-icon': {
                    width: 25,
                    height: 25,
                },
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 5,
            },
        },
        MuiButton: {
            contained: {
                borderRadius: 4,
                padding: '10px 20px',
                whiteSpace: 'nowrap',
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: Colors.purpleHighlight,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: Colors.purpleHighlight,
                    border: `1px solid ${Colors.white}`,
                },

                '&$disabled, &[aria-disabled=true]': {
                    color: `rgba(${Colors.blackRGB}, 0.38)`,
                    textTransform: 'uppercase',
                    backgroundColor: 'transparent',
                    opacity: '100%',
                },
            },
            outlined: {
                borderRadius: 4,
                padding: '10px 20px',
                whiteSpace: 'nowrap',

                '&$disabled, &[aria-disabled=true]': {
                    backgroundColor: 'transparent',
                    opacity: '100%',
                },
            },
            outlinedPrimary: {
                backgroundColor: 'transparent',
                color: Colors.purple,
                border: `2px solid ${Colors.purple}`,
                height: 'fit-content',

                '&:hover': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.04)`,
                    borderWidth: 2,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.12)`,
                    borderWidth: 3,
                },

                '&:active, &[data-clicked=true]': {
                    backgroundColor: 'transparent',
                    borderWidth: 2,
                },

                '&$disabled, &[aria-disabled=true]': {
                    color: `rgba(${Colors.blackRGB}, 0.38)`,
                    border: `2px solid rgba(${Colors.blackRGB}, 0.38)`,
                },
            },
            outlinedSecondary: {
                backgroundColor: 'transparent',
                color: Colors.white,
                border: `2px solid ${Colors.purple}`,

                '&:hover': {
                    backgroundColor: Colors.purpleDarkHighlight,
                    borderWidth: 2,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: Colors.purpleDarkHighlight,
                    borderWidth: 3,
                },

                '&:active, &[data-clicked=true]': {
                    backgroundColor: Colors.purpleDark,
                    borderWidth: 2,
                },

                '&$disabled, &[aria-disabled=true]': {
                    color: `rgba(${Colors.purpleRGB}, 0.8)`,
                    border: `2px solid rgba(${Colors.purpleRGB}, 0.8)`,
                },
            },
            text: {
                padding: '10px 12px',
                borderRadius: 4,
                backgroundColor: 'transparent',
                whiteSpace: 'nowrap',
            },
            textPrimary: {
                color: Colors.purple,

                '&:hover': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.04)`,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.12)`,
                },

                '&:active, &[data-clicked=true]': {
                    backgroundColor: `rgba(${Colors.purpleMediumRGB}, 0.12)`,
                },

                '&$disabled, &[aria-disabled=true]': {
                    color: `rgba(${Colors.blackRGB}, 0.38)`,
                    opacity: '100%',
                },
            },
            textSecondary: {
                color: Colors.white,

                '&:hover': {
                    backgroundColor: Colors.purpleDarkHighlight,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: Colors.purpleDarkHighlight,
                },

                '&:active, &[data-clicked=true]': {
                    backgroundColor: Colors.purpleDark,
                },

                '&$disabled, &[aria-disabled=true]': {
                    color: `rgba(${Colors.blackRGB}, 0.38)`,
                    opacity: '100%',
                },
            },
        },
        MuiFab: {
            primary: {
                backgroundColor: Colors.blue,
                color: Colors.white,
                boxShadow: `-1px 2px 3px 0 rgba(${Colors.blueDarkRGB}, 0.3)`,

                '&:hover': {
                    backgroundColor: Colors.blueHighlight,
                },

                '&:focus, &$focusVisible': {
                    backgroundColor: Colors.blueHighlight,
                    boxShadow: `0 0 4px 0 ${Colors.blue}`,
                },

                '&:active, &[data-clicked=true]': {
                    backgroundColor: Colors.blue,
                },

                '&$disabled, &[aria-disabled=true]': {
                    opacity: '40%',
                    backgroundColor: `${Colors.blue} !important`,
                    color: `${Colors.white} !important`,
                },
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 8,
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '24px 24px 18px',
            },
        },
        MuiDialogContent: {
            dividers: {
                // only show top divider
                borderBottom: 0,
            },
        },
        MuiDialogActions: {
            root: {
                padding: 24,
            },
            spacing: {
                '& > :not(:first-child)': {
                    marginLeft: 12,
                },
            },
        },
        MuiCard: {
            root: {
                borderRadius: 5,
                border: `1px solid ${Colors.greyLight}`,
                boxShadow: `0 1px 10px 0 rgba(${Colors.blackRGB}, 0.1)`,
                marginBottom: 16,
                display: 'flex',
                justifyContent: 'space-between',
            },
        },
        MuiCardContent: {
            root: {
                padding: '20px 24px',
                width: '100%',
            },
        },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '"Roboto", sans-serif',
        h1: {
            fontSize: '3.4rem',
            fontWeight: 400,
            fontFamily: '"Heebo", sans-serif',
        },
        h2: {
            fontSize: '2.4rem',
            fontWeight: 400,
            fontFamily: '"Heebo", sans-serif',
        },
        h3: {
            fontSize: '2.0rem',
            fontWeight: 500,
            fontFamily: '"Heebo", sans-serif',
        },
        subtitle1: {
            fontSize: '1.6rem',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
        },
        subtitle2: {
            fontSize: '1.4rem',
            fontWeight: 500,
            fontFamily: '"Roboto", sans-serif',
        },
        body1: {
            fontSize: '1.6rem',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
        },
        button: {
            fontSize: '1.4rem',
            fontWeight: 500,
            fontFamily: '"Roboto", sans-serif',
            textTransform: 'none',
            lineHeight: 1,
            letterSpacing: '0.0781rem',
        },
        caption: {
            fontSize: '1.2rem',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
        },
        overline: {
            fontSize: '1.0rem',
            fontWeight: 400,
            fontFamily: '"Roboto", sans-serif',
            textTransform: 'uppercase',
        },
    },
    palette: {
        text: {
            primary: `rgba(${Colors.blackRGB}, 0.87)`,
            secondary: Colors.white,
        },
        primary: {
            main: Colors.purple,
            light: Colors.purpleLight,
            dark: Colors.purpleMedium,
            contrastText: Colors.white,
        },
        secondary: {
            main: Colors.purpleDark,
            contrastText: Colors.white,
        },
        background: {
            default: Colors.whiteDark,
            light: Colors.white,
        },
        error: {
            main: Colors.error,
            light: Colors.errorLight,
        },
        success: {
            main: Colors.success,
        },
    },
});

export default theme;
