import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';

import PrimaryButton from '../buttons/primary-button';

import themer from '../../styles/material-theme';
import Styles from './styles';

import ErrorImg from './images/500-error@2x.png';

const ErrorBoundary = ({ classes }) => {
    const history = useHistory();

    const handleBackToHomeClick = () => {
        history.push('/');
        // for some reason history.push('/') here only updates the URL but doesn't load the component
        // so deliberately call for a refresh to force the component to load
        history.go(0);
    };

    return (
        <ThemeProvider theme={themer}>
            <main>
                <div className={`container ${classes.root}`}>
                    <Typography variant="h1" className={classes.title}>
                        Oops!
                    </Typography>

                    <Typography variant="body1" className={classes.description}>
                        Something went wrong on our end.
                    </Typography>

                    <Typography variant="body1" className={classes.description}>
                        Please try again.
                    </Typography>

                    <PrimaryButton
                        className="mt-4 mb-3"
                        onClick={handleBackToHomeClick}
                    >
                        Back to Home
                    </PrimaryButton>

                    <div className={classes.iconContainer}>
                        <img src={ErrorImg} alt="" className={classes.icon} />
                    </div>
                </div>
            </main>
        </ThemeProvider>
    );
};

ErrorBoundary.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(Styles)(ErrorBoundary);
