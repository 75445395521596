import AuthToken from './maintain-auth-token';
import InitializeApp from './app';

const internals = {
    initializers: [
        InitializeApp,
        AuthToken,
    ],
};

export default (store) => {
    internals.initializers.forEach((init) => init(store));
};
