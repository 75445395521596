import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './auth';
import Products from './products';
import Transcript from './transcript';
import User from './user';

const Reducers = (history) => combineReducers({
    auth: Auth,
    products: Products,
    transcript: Transcript,
    user: User,
    router: connectRouter(history),
});

export default Reducers;
