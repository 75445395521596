import React, { createContext, useRef, useContext } from 'react';

import useQuery from '../utils/use-query';

const OfficeContext = createContext();

const OfficeProvider = ({ children }) => { // eslint-disable-line react/prop-types
    const query = useQuery();
    const isOffice = useRef(query.get('mode') === 'addIn');

    // if we need to add access to the Office SDK we can make that available here
    const value = {
        isOffice: isOffice.current,
    };

    return (
        <OfficeContext.Provider value={value}>{children}</OfficeContext.Provider>
    );
};

const useOffice = () => {
    const context = useContext(OfficeContext);
    if (context === undefined) {
        throw new Error('useOffice must be used within an OfficeProvider');
    }
    return context;
};

export { OfficeProvider, useOffice };
