import Colors from '../../styles/colors';

const styles = (theme) => ({
    root: {
        width: '80%',
        borderRadius: 8,
        padding: 20,
        backgroundColor: Colors.purpleLight,

        [theme.breakpoints.down('md')]: {
            width: '90%',
        },

        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    wrapper: {
        flexDirection: 'row',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    icon: {
        width: 20,
        height: 20,
        marginRight: 16,
        color: Colors.purple,
    },
    buttonsWrapper: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    },
});

export default styles;
