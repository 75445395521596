import React, { Suspense, lazy, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import withRoot from '../wiring/with-root';
import { useOffice } from '../wiring/use-office';
import themer from '../styles/material-theme';
import { isAuthenticated as getAuthenticated } from '../selectors/auth';
import { getCookiePolicyAcceptance } from '../selectors/user';
import InitializerUser from '../initializers/user';
import CookiePolicySnackbar from '../components/cookie-policy-snackbar';

const PublicLayout = lazy(() => import('../layouts/public'));
const SignInLayout = lazy(() => import('../layouts/sign-in'));
const AppLayout = lazy(() => import('../layouts/app'));

const App = ({ store }) => {
    const location = useLocation();
    const isAuthenticated = useSelector(getAuthenticated);
    const cookiePolicyAccepted = useSelector(getCookiePolicyAcceptance);
    const { isOffice } = useOffice();

    useLayoutEffect(() => {
        if (isAuthenticated) {
            InitializerUser(store);
        }
    }, [isAuthenticated]);

    return (
        <>
            <Suspense fallback={<div />}>
                <Switch location={location}>
                    <Route path="/app">
                        {isAuthenticated ? <AppLayout /> : <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />}
                    </Route>
                    <Route path="/sign-in">
                        <SignInLayout />
                    </Route>
                    <Route path="/sign-up">
                        <SignInLayout />
                    </Route>
                    <Route path="/">
                        <PublicLayout />
                    </Route>
                </Switch>
            </Suspense>

            <CookiePolicySnackbar isOpen={!isOffice && !isAuthenticated && !cookiePolicyAccepted} />
        </>
    );
};

App.propTypes = {
    store: PropTypes.object.isRequired,
};

export default withRoot(App, themer);
