import KeyMirror from 'keymirror';

import TranscriptTypes from '../action-types/transcript';
import AuthTypes from '../action-types/auth';

const REQUEST_STATUS = KeyMirror({
    ACTIVE: true,
    SUCCESS: true,
    ERROR: true,
});

const internals = {
    initial: () => ({
        requestStatus: null,
        errorMsg: null,
    }),
};

const TranscriptReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
    case TranscriptTypes.UPLOAD_TRANSCRIPT_FILE_BEGIN:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ACTIVE,
            errorMsg: null,
        };
    case TranscriptTypes.UPLOAD_TRANSCRIPT_FILE_SUCCESS:
        return {
            ...state,
            ...payload,
            transcript: payload.transcript,
            requestStatus: REQUEST_STATUS.SUCCESS,
        };
    case TranscriptTypes.UPLOAD_TRANSCRIPT_FILE_ERROR:
        return {
            ...state,
            requestStatus: REQUEST_STATUS.ERROR,
            errorMsg: payload.errorMsg,
        };
    case TranscriptTypes.UPDATE_TRANSCRIPT_TITLE:
        return {
            ...state,
            title: payload.title,
        };

    // clear all stored data on logout
    case AuthTypes.LOGOUT:
        return {
            ...internals.initial(),
        };

    default:
        // do nothing
    }
    return state;
};

export default TranscriptReducer;
