import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Snackbar from '@material-ui/core/Snackbar';

import PrimaryButton from '../buttons/primary-button';
import TextButton from '../buttons/text-button';
import { acceptCookiePolicy } from '../../actions/user';

import Styles from './styles';

const CookiePolicySnackbar = ({ classes, isOpen }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleAgree = () => {
        dispatch(acceptCookiePolicy());
    };

    const handleLearnMore = () => {
        history.push('/cookie-policy');
    };

    return (
        <Snackbar
            className={classes.root}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={isOpen}
        >
            <div className={`row no-gutters ${classes.wrapper}`}>
                <div className="col d-flex align-items-start">
                    <InfoOutlinedIcon className={classes.icon} />

                    <Typography variant="body1">
                        We use cookies to monitor the performance of our website, improve user experience, and assist in our marketing efforts.
                        By continuing to browse our site, you agree to our use of cookies.
                    </Typography>
                </div>

                <div className={`col col-md-auto ${classes.buttonsWrapper}`}>
                    <TextButton
                        className="mr-2"
                        onClick={handleLearnMore}
                    >
                        Learn More
                    </TextButton>

                    <PrimaryButton
                        onClick={handleAgree}
                    >
                        I Agree
                    </PrimaryButton>
                </div>
            </div>
        </Snackbar>
    );
};

CookiePolicySnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default withStyles(Styles)(CookiePolicySnackbar);
