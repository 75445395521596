import ReactGA from 'react-ga';
import GA4React from 'ga-4-react';
import History from '../wiring/history';

let ga4React;
const GAUtils = {};
const debug = false;
// use this below to prevent blowups when not using GA
let initialized = false; // eslint-disable-line  @typescript-eslint/no-unused-vars
GAUtils.initGA = async () => {
    initialized = true; // eslint-disable-line  @typescript-eslint/no-unused-vars

    if (process.env.REACT_APP_GA) {
        ReactGA.initialize(process.env.REACT_APP_GA, {
            debug,
            titleCase: false,
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        // tell GA everytime the URL changes (includes hashes)
        History.listen((location) => ReactGA.pageview(location.pathname + location.search));
    }

    if (process.env.REACT_APP_GA4) {
        ga4React = new GA4React(process.env.REACT_APP_GA4);
        await ga4React.initialize();
        // path,location,title
        // title is what shows in the top view in the GA dash, and by default it's
        // always going to be the app name, which is worthless info.
        History.listen((location) => ga4React.pageview(location.pathname, location.search, location.pathname));
    }
};

export default GAUtils;

// To use:
// import { CustomEventHere } from '../../utils/ga';
// then inside whatever should trigger it:
// CustomEventHere();
// export const CustomEventHere = () => {
//     if (initialized) {
//         ReactGA.event({
//             category: 'yourCategory',
//             action: 'yourAction',
//         });
//         // using customEvent like below is enough to create the event, so it starts showing in the dash
//         // There's some more flexibility here, second param is 'action', but you can pass
//         // a third param that's an object, see GA4 docs FMI
//         ga4React.gtag('event', 'customEvent');
//     }
// };
